#fh5co-featured {
    padding: 7em 0;
    /* background: #5b4e44 url(../images/wood_1.png) repeat; */
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured {
      padding: 5em 0;
    }
  }
  #fh5co-featured .fh5co-heading .heading {
    position: relative;
    color: #fff;
    margin-top: -50px;
  }
  #fh5co-featured .fh5co-heading .sub-heading {
    color: #a99c92;
  }
  #fh5co-featured .fh5co-grid {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  #fh5co-featured .fh5co-grid h2 {
    font-size: 20px;
    margin-bottom: 0;
  }
  #fh5co-featured .fh5co-grid p {
    line-height: 1.6;
    font-size: 16px;
    color: #222;
  }
  #fh5co-featured .fh5co-grid .pricing {
    font-size: 30px;
    display: block;
    padding-top: 20px;
  }
  #fh5co-featured .fh5co-grid .pricing:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 2px;
    left: 50%;
    margin-left: -20px;
    background: #fb6e14;
  }
  #fh5co-featured .fh5co-grid .arrow-left:before, #fh5co-featured .fh5co-grid .arrow-left::before {
    content: "";
    position: absolute;
    z-index: 999;
    top: 50px;
    left: 0;
    margin-left: -15px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #f5f5f5;
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured .fh5co-grid .arrow-left:before, #fh5co-featured .fh5co-grid .arrow-left::before {
      right: auto !important;
      left: 15px !important;
      top: 0 !important;
      margin-top: -15px !important;
      margin-left: 0px !important;
      border-top: none !important;
      border-left: 15px solid transparent !important;
      border-right: 15px solid transparent !important;
      border-bottom: 15px solid #f5f5f5 !important;
    }
  }
  #fh5co-featured .fh5co-grid .arrow-right:before, #fh5co-featured .fh5co-grid .arrow-right::before {
    content: "";
    position: absolute;
    z-index: 999;
    top: 50px;
    right: 0;
    margin-right: -15px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #f5f5f5;
  }
  @media screen and (max-width: 992px) {
    #fh5co-featured .fh5co-grid .arrow-right:before, #fh5co-featured .fh5co-grid .arrow-right::before {
      right: auto !important;
      left: 0 !important;
      margin-left: -15px !important;
      border-left: none !important;
      border-right: 15px solid #f5f5f5 !important;
    }
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured .fh5co-grid .arrow-right:before, #fh5co-featured .fh5co-grid .arrow-right::before {
      right: auto !important;
      left: 15px !important;
      top: 0 !important;
      margin-top: -15px !important;
      margin-left: 0px !important;
      border-top: none !important;
      border-left: 15px solid transparent !important;
      border-right: 15px solid transparent !important;
      border-bottom: 15px solid #f5f5f5 !important;
    }
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half {
    width: 50%;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  @media screen and (max-width: 992px) {
    #fh5co-featured .fh5co-grid > .fh5co-v-half {
      width: 100%;
    }
  }
  #fh5co-featured {
    padding: 7em 0;
    background: #71b12f;
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-text {
    background: #faebcd;
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-special-1 {
    background: #faebcd;
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-special-1.arrow-left:before, #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-special-1.arrow-left::before {
    border-right: 15px solid #faebcd;
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-special-1.arrow-left:before, #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-special-1.arrow-left::before {
      border-left: 15px solid transparent !important;
      border-right: 15px solid transparent !important;
      border-bottom: 15px solid #faebcd !important;
    }
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-v-col-2 {
    width: 50%;
    padding: 20px;
    position: relative;
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-v-col-2.fh5co-bg-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-v-col-2.fh5co-bg-img {
      height: 200px;
    }
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-v-col-2 {
      width: 100%;
    }
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-h-row-2 {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-h-row-2 > .fh5co-v-col-2 {
    width: 50%;
    position: relative;
    padding: 20px;
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-h-row-2 > .fh5co-v-col-2 {
      width: 100%;
    }
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-h-row-2 > .fh5co-v-col-2.fh5co-bg-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media screen and (max-width: 768px) {
    #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-h-row-2 > .fh5co-v-col-2.fh5co-bg-img {
      height: 200px;
    }
  }
  #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-h-row-2.fh5co-reversed .fh5co-bg-img {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
  }
  @media screen and (max-width: 992px) {
    #fh5co-featured .fh5co-grid > .fh5co-v-half > .fh5co-h-row-2.fh5co-reversed .fh5co-bg-img {
      position: relative;
      right: inherit;
      top: inherit;
      bottom: inherit;
    }
  }



  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container:before, .container:after {
    content: " ";
    display: table;
  }
  .container:after {
    clear: both;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
  
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table;
  }
  .container-fluid:after {
    clear: both;
  }

  #fh5co-type > .container {
    z-index: 10;
    position: relative;
  }
  #fh5co-events > .container {
    z-index: 10;
    position: relative;
  }

  .container .jumbotron, .container-fluid .jumbotron {
    border-radius: 6px;
  }
  .jumbotron .container {
    max-width: 100%;
  }
  @media screen and (min-width: 768px) {
    .jumbotron {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    .container .jumbotron, .container-fluid .jumbotron {
      padding-left: 60px;
      padding-right: 60px;
    }
    .jumbotron h1,
    .jumbotron .h1 {
      font-size: 63px;
    }
  }

  @media (min-width: 768px) {
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
      margin-left: -15px;
    }
  }

    .container > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
    }
    @media (min-width: 768px) {
    .container > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
      }
    .row:before, .row:after {
        content: " ";
        display: table;
    }
    .row:after {
        clear: both;
    }

    .row-padded {
        padding-bottom: 40px;
    }

    .text-center {
    text-align: center;
    }

    .fh5co-heading .heading {
        font-size: 50px;
        font-style: italic;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
    .fh5co-heading .heading {
        font-size: 30px;
    }
    }
    .fh5co-heading .heading:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 40px;
    height: 2px;
    left: 50%;
    background: #fb6e14;
    margin-left: -20px;
    }
    .fh5co-heading .sub-heading {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
    .fh5co-heading .sub-heading {
        font-size: 18px;
    }
    }


    #fh5co-featured .fh5co-heading .heading {
    position: relative;
    color: #fff;
    margin-top: -50px;
    }
    #fh5co-featured .fh5co-heading .sub-heading {
    color: #a99c92;
    }

    #fh5co-menus .fh5co-heading .heading {
    position: relative;
    padding-top: 30px !important;
    }
    #fh5co-menus .fh5co-heading .heading:before, #fh5co-menus .fh5co-heading .heading::before {
    height: 64px;
    width: 64px;
    position: absolute;
    content: "";
    /* background: url(../images/0401-vegan.png) no-repeat; */
    top: 0;
    left: 50%;
    margin-top: -50px;
    margin-left: -32px;
    }
    #fh5co-menus .fh5co-heading .sub-heading {
    color: #a99c92;
    }

    #fh5co-events .fh5co-heading .heading {
    position: relative;
    padding-top: 30px !important;
    color: #fff;
    }
    #fh5co-events .fh5co-heading .heading:before, #fh5co-events .fh5co-heading .heading::before {
    height: 64px;
    width: 49px;
    position: absolute;
    content: "";
    /* background: url(../images/0402-chef.png) no-repeat; */
    top: 0;
    left: 50%;
    margin-top: -50px;
    margin-left: -24px;
    }
    #fh5co-events .fh5co-heading .sub-heading {
    color: #a99c92;
    }

    #fh5co-contact .fh5co-heading .heading {
    position: relative;
    padding-top: 30px !important;
    }
    #fh5co-contact .fh5co-heading .heading:before, #fh5co-contact .fh5co-heading .heading::before {
    height: 61px;
    width: 61px;
    position: absolute;
    content: "";
    /* background: url(../images/0203-coffee-love.png) no-repeat; */
    top: 0;
    left: 50%;
    margin-top: -50px;
    margin-left: -24px;
    }
    #fh5co-contact .fh5co-heading .sub-heading {
    color: #a99c92;
    }

    .row-padded {
        padding-bottom: 40px;
      }

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    }
      
    @media (min-width: 992px) {
        .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
          float: left;
        }
      
        .col-md-1 {
          width: 8.33333%;
        }
      
        .col-md-2 {
          width: 16.66667%;
        }
      
        .col-md-3 {
          width: 25%;
        }
      
        .col-md-4 {
          width: 33.33333%;
        }
      
        .col-md-5 {
          width: 41.66667%;
        }
      
        .col-md-6 {
          width: 50%;
        }
      
        .col-md-7 {
          width: 58.33333%;
        }
      
        .col-md-8 {
          width: 66.66667%;
        }
      
        .col-md-9 {
          width: 75%;
        }
      
        .col-md-10 {
          width: 83.33333%;
        }
      
        .col-md-11 {
          width: 91.66667%;
        }
      
        .col-md-12 {
          width: 100%;
        }
      
        .col-md-pull-0 {
          right: auto;
        }
      
        .col-md-pull-1 {
          right: 8.33333%;
        }
      
        .col-md-pull-2 {
          right: 16.66667%;
        }
      
        .col-md-pull-3 {
          right: 25%;
        }
      
        .col-md-pull-4 {
          right: 33.33333%;
        }
      
        .col-md-pull-5 {
          right: 41.66667%;
        }
      
        .col-md-pull-6 {
          right: 50%;
        }
      
        .col-md-pull-7 {
          right: 58.33333%;
        }
      
        .col-md-pull-8 {
          right: 66.66667%;
        }
      
        .col-md-pull-9 {
          right: 75%;
        }
      
        .col-md-pull-10 {
          right: 83.33333%;
        }
      
        .col-md-pull-11 {
          right: 91.66667%;
        }
      
        .col-md-pull-12 {
          right: 100%;
        }
      
        .col-md-push-0 {
          left: auto;
        }
      
        .col-md-push-1 {
          left: 8.33333%;
        }
      
        .col-md-push-2 {
          left: 16.66667%;
        }
      
        .col-md-push-3 {
          left: 25%;
        }
      
        .col-md-push-4 {
          left: 33.33333%;
        }
      
        .col-md-push-5 {
          left: 41.66667%;
        }
      
        .col-md-push-6 {
          left: 50%;
        }
      
        .col-md-push-7 {
          left: 58.33333%;
        }
      
        .col-md-push-8 {
          left: 66.66667%;
        }
      
        .col-md-push-9 {
          left: 75%;
        }
      
        .col-md-push-10 {
          left: 83.33333%;
        }
      
        .col-md-push-11 {
          left: 91.66667%;
        }
      
        .col-md-push-12 {
          left: 100%;
        }
      
        .col-md-offset-0 {
          margin-left: 0%;
        }
      
        .col-md-offset-1 {
          margin-left: 8.33333%;
        }
      
        .col-md-offset-2 {
          margin-left: 16.66667%;
        }
      
        .col-md-offset-3 {
          margin-left: 25%;
        }
      
        .col-md-offset-4 {
          margin-left: 33.33333%;
        }
      
        .col-md-offset-5 {
          margin-left: 41.66667%;
        }
      
        .col-md-offset-6 {
          margin-left: 50%;
        }
      
        .col-md-offset-7 {
          margin-left: 58.33333%;
        }
      
        .col-md-offset-8 {
          margin-left: 66.66667%;
        }
      
        .col-md-offset-9 {
          margin-left: 75%;
        }
      
        .col-md-offset-10 {
          margin-left: 83.33333%;
        }
      
        .col-md-offset-11 {
          margin-left: 91.66667%;
        }
      
        .col-md-offset-12 {
          margin-left: 100%;
        }
    }

    .js .to-animate, .js
    .to-animate-2 {
      opacity: 0;
      color: #000000;
    }

    .fh5co-heading .sub-heading {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
    .fh5co-heading .sub-heading {
        font-size: 18px;
    }
    }

    #fh5co-featured .fh5co-heading .sub-heading {
    color: #a99c92;
    }
    #fh5co-menus .fh5co-heading .sub-heading {
    color: #a99c92;
    }
    #fh5co-events .fh5co-heading .sub-heading {
    color: #a99c92;
    }
    #fh5co-contact .fh5co-heading .sub-heading {
    color: #a99c92;
    }

    #fh5co-home .flexslider .fh5co-text {
        position: absolute;
        z-index: 3;
        width: 100%;
        top: 50%;
        margin-top: -212px;
        text-align: center;
      }
      #fh5co-home .flexslider .fh5co-text h1 {
        margin: 0;
        padding: 0;
        font-family: "Playfair Display", serif;
        font-size: 300px;
        font-weight: 700;
        font-style: italic;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
      }
      @media screen and (max-width: 992px) {
        #fh5co-home .flexslider .fh5co-text h1 {
          font-size: 100px;
        }
      }
      #fh5co-home .flexslider .fh5co-text h2 {
        margin: 0;
        padding: 0;
        font-family: "Merriweather", serif;
        font-weight: 300;
        font-size: 40px;
        letter-spacing: 2px;
        color: #fff;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
      }
      @media screen and (max-width: 768px) {
        #fh5co-home .flexslider .fh5co-text h2 {
          font-size: 20px;
        }
      }
      #fh5co-home .flexslider .fh5co-text h2 span {
        font-family: "Playfair Display", serif;
        font-style: italic;
        text-transform: none;
      }
      #fh5co-home .flexslider .fh5co-text h2 a {
        color: #fb6e14;
      }

      #fh5co-about .fh5co-text {
        padding: 2em 6em;
        text-align: left;
        /* background: #130d08 url(../images/wood_1.png) repeat; */
      }
      @media screen and (max-width: 1200px) {
        #fh5co-about .fh5co-text {
          padding: 1em;
        }
      }
      #fh5co-about .fh5co-text .btn {
        color: #fff;
      }
      #fh5co-about .fh5co-text .heading {
        margin-bottom: 30px;
        color: #fff;
        font-style: italic;
        font-weight: 300;
        position: relative;
        padding-bottom: 30px;
        text-align: center;
      }
      #fh5co-about .fh5co-text .heading:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 40px;
        height: 2px;
        left: 50%;
        background: #fb6e14;
        margin-left: -20px;
      }
      #fh5co-about .fh5co-text p {
        line-height: 2;
        color: #a99c92;
      }
      #fh5co-about .fh5co-text p span.firstcharacter {
        float: left;
        color: #903;
        font-size: 75px;
        line-height: 60px;
        padding-top: 4px;
        padding-right: 10px;
        padding-left: 3px;
        font-style: italic;
        top: -20px;
        position: relative;
        color: #fff;
        font-family: "Playfair Display", serif;
      }

    @media screen and (max-width: 768px) {
        #slidercafes{
            width: 100%;
        }
        #my-sliderbebidas{
        width: 100%;
        margin-bottom: 50px;
        }
        #my-slidersnaks{
            width: 100%;
        }
        #my-sliderpastelaria{
            width: 100%;
            margin-bottom: 50px;
        }
        .bordersp1{
            margin-top: 20px;
        }
        .menuoca{
            display: none !important;
        }
        .textofale{
            font-size: 25px !important;
        }
        .imagenservicos1princ{
                margin-bottom: 10px;
        }
        .imagenservicos2princ{
                margin-top: 10px;
                margin-bottom: 10px;
        }
        .imagenservicos3princ{
        margin-top: 10px;
                margin-bottom: 10px;
        }
        .imagenservicos4princ{
        margin-top: 10px;
                margin-bottom: 10px;
        }
        .imagenservicos5princ{
        margin-top: 10px;
                margin-bottom: 10px;
        }
        .imagenservicos6princ{
        margin-top: 10px;
            
        }
        .imagenservicos1{
        margin-left: 0px;
        margin-bottom: 0px;
        margin-right: 0px;
        }
        .imagemservicos2{
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        }
        .imagemservicos3{
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        }
        .imagemservicos4{
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        }
        .imagemservicos5{
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        }
        .imagemservicos6{
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        }
        .sliderlogosprincipal{
        display: none;
        }
        #fh5co-type .fh5co-type {
            margin-bottom: 30px;
        }
    }

    @media (max-width: 360px) {
        #slidercafes{
        width: 100%;
      }
      #my-sliderbebidas{
      width: 100%;
      margin-bottom: 50px;
      }
      #my-slidersnaks{
        width: 100%;
      }
      #my-sliderpastelaria{
        width: 100%;
        margin-bottom: 50px;
      }
      .bordersp1{
        margin-top: 20px;
      }
      .menuoca{
        display: none !important;
      }
      .imagenservicos1princ{
            margin-bottom: 10px;
      }
      .imagenservicos2princ{
            margin-top: 10px;
            margin-bottom: 10px;
      }
      .imagenservicos3princ{
    margin-top: 10px;
            margin-bottom: 10px;
      }
      .imagenservicos4princ{
    margin-top: 10px;
            margin-bottom: 10px;
      }
      .imagenservicos5princ{
    margin-top: 10px;
            margin-bottom: 10px;
      }
      .imagenservicos6princ{
    margin-top: 10px;
          
      }
    
        .imagenservicos1{
            margin-left: 0px;
            margin-bottom: 0px;
            margin-right: 0px;
        }
        .imagemservicos2{
            margin-right: 0px;
            margin-left: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .imagemservicos3{
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
        }
        .imagemservicos4{
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
        }
        .imagemservicos5{
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .imagemservicos6{
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 0px;
        }
        .sliderlogosprincipal{
        display: none;
        }
        .img_bebidasquentes{
            height: 500px;
            width: auto !important;
            margin-left: 10% !important;
        }
        .img_bebidasfrias{
            margin-left: 5% !important;
        }
        .img_snaksecomida{
            margin-left: 20% !important;
        }
        .ism-slider{
            margin-left: 0% !important;
            width: 100% !important;
        }
        .textofale{
            font-size: 25px !important;
        }
    }

    .imagenservicos1{
        margin-left: -10px;
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .imagemservicos2{
        margin-right: 10px;
        margin-left: -10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .imagemservicos3{
        margin-left: -10px;
        margin-right: 10px;
        margin-top: 10px;
    }
    .imagemservicos4{
        margin-left: 10px;
        margin-right: -10px;
        margin-top: 10px;
    }
    .imagemservicos5{
        margin-left: 10px;
        margin-right: -10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .imagemservicos6{
        margin-left: 10px;
        margin-right: -10px;
        margin-bottom: 10px;
    }
    
    #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-text {
        background: #faebcd;
    }
    #fh5co-featured .fh5co-grid > .fh5co-v-half .fh5co-special-1 {
        background: #faebcd;
    }

    .averias {
        background-image: url(./images/servicio-tecnico-averias.jpg)
    }

    .cafe1 {
        background-image: url(./sandes/cafe1.png)
    }

    .sandes1 {
        background-image: url(./sandes/sandes1.png)
    }

    .stock{
        background-image: url(./images/stock.jpg)
    }

    .latas_de_refrigerantes {
        background-image: url(./images/latas_de_refrigerantes.jpg)
    }

    .maquina55 {
        background-image: url(./images/maquina55.png)
    }