/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

body {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Cocon";
  src: local("Cocon"), url(../fonts/Cocon-Regular.ttf) format("opentype");
}

@media only screen and (max-width: 1300px) {
  #equipamentosDiv, #contactoDiv{
    padding-inline: 20px;
  }
  #footer_id{
    margin-left: 5px;
  }

  header {
    padding-inline: 20px;
  }
}
@media only screen and (max-width: 800px) {
  #servicos_id {
    margin-left: 10px;
    margin-right: 10px;
  }
  .imagenservicos1 {
    margin-left: 1px !important;
    margin-right: 1px !important; 
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .imagemservicos2  {
    margin-left: 1px !important;
    margin-right: 1px !important; 
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .imagemservicos3 {
    margin-left: 1px !important;
    margin-right: 1px !important; 
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .imagemservicos4  {
    margin-left: 1px !important;
    margin-right: 1px !important; 
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .imagemservicos5 {
    margin-left: 1px !important;
    margin-right: 1px !important; 
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .imagemservicos6  {
    margin-left: 1px !important;
    margin-right: 1px !important; 
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
}

